<template>
  <div class="d-flex flex-column ml-1">
    <div>
      <h4 class="mb-0">
        {{ mentee.user.name }} {{ mentee.user.surname }}
      </h4>
      <div>
        <span class="card-text">{{ $t('Registration date') }}</span>:
        <span class="card-text">{{ mentee.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit'  }) }}</span>
      </div>
      <div>
        <span class="card-text">{{ $t('Mentee ID') }}</span>:
        <span class="card-text">{{ mentee.id }}</span>
      </div>
      <div v-if="mentee.is_on_mentorship">
        <feather-icon icon="CheckIcon" class="text-primary" size="16" />
        <span class="card-text text-primary ml-1">{{ $t('Mentee is on mentorship') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCardText
} from 'bootstrap-vue'
import { formatGreekDate } from '@mentoring-platform/utils/filters'

export default {
  components: {
    BCardText
  },
  filters: {
    formatGreekDate
  },
  props: {
    mentee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  beforeMount() {
    this.$bus.$on('current-mentorship-stopped-by-admin', this.unsetIsOnMentorship)
    this.$bus.$on('new-mentorship-created-for-mentee-by-admin', this.setIsOnMentorship)
  },
  beforeDestroy() {
    this.$bus.$off('current-mentorship-stopped-by-admin', this.unsetIsOnMentorship)
    this.$bus.$off('new-mentorship-created-for-mentee-by-admin', this.setIsOnMentorship)
  },
  methods: {
    setIsOnMentorship() {
      this.mentee.is_on_mentorship = true
    },
    unsetIsOnMentorship() {
      this.mentee.is_on_mentorship = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
<template>
  <b-card no-body>
    <b-card-header v-if="$can('read', 'admin')">
      <b-card-title>
        {{ $t('My packages') }}
      </b-card-title>
    </b-card-header>
    <b-card-body
      v-if="userSubscriptions.length"
    >
      <b-table-simple responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th>
              {{ $t('Package') | upper }}
            </b-th>
            <b-th>
              {{ $t('Purchase date') | upper }}
            </b-th>
            <b-th>
              {{ $t('Scholarship') | upper }}
            </b-th>
            <b-th>
              {{ $t('Price') | upper }}
            </b-th>
            <b-th>

            </b-th>
            <b-th>
              {{ $t('Status') | upper }}
            </b-th>
            <b-th v-if="canCreateNewMentorship">

            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(subscription) in userSubscriptions">
            <b-tr :key="subscription.subscription_id" >
              <b-th :rowspan="subscription.packages.length">
                {{ $t(subscription.subscription_title) }}
              </b-th>
              <b-td :rowspan="subscription.packages.length">
                {{ subscription.purchased_at }}
              </b-td>
              <b-td :rowspan="subscription.packages.length">
                <b-card-text v-if="subscription.scholarship">
                  {{ $t('Yes') }} ({{ subscription.scholarship.name }})
                </b-card-text>
                <b-card-text v-else>
                  {{ $t('No') }}
                </b-card-text>
              </b-td>
              <b-td :rowspan="subscription.packages.length">
                {{ subscription.price | price }}
              </b-td>
              <b-td>
                {{ $t(subscription.packages[0].description) }}
              </b-td>
              <b-td>
                <b-badge :variant="packageStatusToColorVariant(subscription.packages[0].status)">
                  {{ packageStatusToText(subscription.packages[0].status) }}
                </b-badge>
              </b-td>
              <b-td v-if="canCreateNewMentorship">
                <b-button variant="primary" v-if="canCreateNewMentorshipForPackage(subscription.packages[0])" @click="createNewMentorship(subscription.packages[0])">
                  {{ $t('New mentorship') }}
                </b-button>
              </b-td>
            </b-tr>
            <b-tr v-for="position in subscription.packages.length - 1" :key="subscription.subscription_id + '-' + position">
              <b-td>
                {{ $t(subscription.packages[position].description) }}
              </b-td>
              <b-td>
                <b-badge :variant="packageStatusToColorVariant(subscription.packages[position].status)">
                  {{ packageStatusToText(subscription.packages[position].status) }}
                </b-badge>
              </b-td>
              <b-td v-if="canCreateNewMentorship">
                <b-button variant="primary" v-if="canCreateNewMentorshipForPackage(subscription.packages[position])" @click="createNewMentorship(subscription.packages[position])">
                  {{ $t('New mentorship') }}
                </b-button>
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
      <create-new-mentorship-modal ref="create-new-mentorship-modal" />
    </b-card-body>
    <b-card-body
      v-else
      body-class="no-data-card-body"
    >
      <feather-icon
        icon="PackageIcon"
        size="25"
      />
      <b-card-text class="mb-1">
        {{ $t('You do not have any purchased package yet.') }}
      </b-card-text>
      <b-button v-if="$can('read', 'mentee')" variant="primary" :to="{ name: 'mentoring-packages' }">
        {{ $t('View mentoring packages') }}
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BLink,
  BRow,
  BTableSimple,
  BTbody,
  BThead,
  BTr,
  BTh,
  BTd
} from 'bootstrap-vue'
import { useUserPackagesRepository } from '@mentoring-platform/composables'
import { upper, price } from '@mentoring-platform/utils/filters'
import { packageStatuses } from '@mentoring-platform/constants'
import CreateNewMentorshipModal from '@mentoring-platform/views/components/blocks/MenteeAdmin/CreateNewMentorshipModal.vue'

export default {
  components: {
    BBadge,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCol,
    BLink,
    BRow,
    BTableSimple,
    BTbody,
    BThead,
    BTr,
    BTh,
    BTd,
    CreateNewMentorshipModal
  },
  props: {
    mentee: {
      type: Object
    }
  },
  filters: {
    price,
    upper
  },
  data() {
    return {
      packageStatuses
    }
  },
  computed: {
    canCreateNewMentorship() {
      return this.$can('read', 'admin') &&
        !this.mentee.is_on_mentorship &&
        this.userSubscriptions.filter(
          subscription => subscription.packages.filter(subscriptionPackage => subscriptionPackage.status === this.packageStatuses.PACKAGE_STATUS_PENDING).length > 0
        ).length == 0
    }
  },
  beforeMount() {
    this.$bus.$on('mentee-subscriptions-list-needs-refresh', this.fetchMenteeSubsriptions)
    this.$bus.$on('current-mentorship-stopped-by-admin', this.fetchMenteeSubsriptions)
    this.$bus.$on('new-mentorship-created-for-mentee-by-admin', this.fetchMenteeSubsriptions)
  },
  beforeDestroy() {
    this.$bus.$off('mentee-subscriptions-list-needs-refresh', this.fetchMenteeSubsriptions)
    this.$bus.$off('current-mentorship-stopped-by-admin', this.fetchMenteeSubsriptions)
    this.$bus.$off('new-mentorship-created-for-mentee-by-admin', this.fetchMenteeSubsriptions)
  },
  methods: {
    canCreateNewMentorshipForPackage(subscriptionPackage) {
      return !subscriptionPackage.is_coaching && subscriptionPackage.status == this.packageStatuses.PACKAGE_STATUS_PAID
    },
    fetchMenteeSubsriptions() {
      this.getUserSubscriptions(this.mentee)
    },
    createNewMentorship(subscriptionPackage) {
      this.$refs['create-new-mentorship-modal'].show(subscriptionPackage)
    }
  },
  setup(props) {
    const {
      userSubscriptions,
      getUserSubscriptions,
      packageStatusToText,
      packageStatusToColorVariant
    } = useUserPackagesRepository()
    getUserSubscriptions(props.mentee)

    return {
      userSubscriptions,
      getUserSubscriptions,
      packageStatusToText,
      packageStatusToColorVariant
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <b-card>
      <scholarships-table
        :scholarships="scholarships"
        @remove-scholarship="removeScholarship"
      />
      <b-button
        variant="primary"
        @click="showModal"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Give scholarship to mentee') }}
      </b-button>
      <give-scholarship-to-mentee-modal
        ref="give-scholarship-to-mentee-modal"
        :mentee="mentee"
      />
    </b-card>
    <remove-scholarship-modal ref="remove-scholarship-modal" />
  </div>
</template>

<script>
import {
  BButton,
  BCard
} from 'bootstrap-vue'
import { useScholarship } from '@mentoring-platform/composables'
import GiveScholarshipToMenteeModal from '@mentoring-platform/views/components/blocks/MenteeAdmin/GiveScholarshipToMenteeModal.vue'
import ScholarshipsTable from '@mentoring-platform/views/components/blocks/MenteeAdmin/ScholarshipsTable.vue'
import RemoveScholarshipModal from '@mentoring-platform/views/components/blocks/MenteeAdmin/RemoveScholarshipModal.vue'

export default {
  components: {
    BButton,
    BCard,
    RemoveScholarshipModal,
    GiveScholarshipToMenteeModal,
    ScholarshipsTable
  },
  props: {
    mentee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {

    }
  },
  beforeMount() {
    this.$bus.$on('mentee-scholarships-list-needs-refresh', this.fetchMenteeScholarships)
  },
  beforeDestroy() {
    this.$bus.$off('mentee-scholarships-list-needs-refresh', this.fetchMenteeScholarships)
  },
  methods: {
    showModal() {
      this.$refs['give-scholarship-to-mentee-modal'].show()
    },
    fetchMenteeScholarships() {
      this.getScholarshipsOfMentee(this.mentee)
    },
    removeScholarship(scholarship) {
      this.$refs['remove-scholarship-modal'].show(scholarship)
    }
  },
  setup(props) {
    const { scholarships, getScholarshipsOfMentee } = useScholarship()
    getScholarshipsOfMentee(props.mentee)

    return {
      scholarships,
      getScholarshipsOfMentee
    }
  }
}
</script>

<style lang="scss">

</style>

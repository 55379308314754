<template>
  <b-modal
    id="create-new-mentorship-modal"
    ref="create-new-mentorship-modal"
    centered
    size="lg"
    :title="$t('New mentorship')"
    title-class="font-weight-bolder"
    ok-only
    :ok-title="$t('New mentorship')"
    @ok.prevent="askForConfirmation"
    :ok-disabled="!selectedMentor"
    @hidden="onHidden"
  >
    <div class="mb-2">
      <b-form-input
        v-model="searchQuery"
        type = "search"
        class="d-inline-block mr-1"
        :placeholder="$t('Search...')"
      />
    </div>
    <b-table
      v-if="availableMentors"
      :items="availableMentors"
      responsive
      :fields="fields"
      :filter="searchQuery"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="''"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative mb-2"
      no-provider-sorting
      no-provider-paging
      no-provider-filtering
      no-sort-reset
      :per-page="perPage"
      :current-page="currentPage"
      selectable
      select-mode="single"
      selected-variant="primary"
      @row-selected="mentorSelected"
    >
      <template #head()="{label}">
        {{ label | upper }}
      </template>

      <!-- Column: fullname -->
      <!-- eslint-disable-next-line -->
      <template #cell(user.surname)="data">
        {{ data.item.user.name }} {{ data.item.user.surname }}
      </template>
    </b-table>
    <div class="d-flex justify-content-center">
      <b-pagination
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        :per-page="perPage"
        v-model="currentPage"
        first-number
        last-number
        :total-rows="totalRows"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
    <confirm-mentorship-creation-modal ref="confirm-mentorship-creation-modal" @creation-confirmed="createNewMentorship" />
  </b-modal>
</template>

<script>
import {
  BFormInput,
  BModal,
  BPagination,
  BTable
} from 'bootstrap-vue'
import { upper } from '@mentoring-platform/utils/filters'
import ConfirmMentorshipCreationModal from '@mentoring-platform/views/components/blocks/MenteeAdmin/ConfirmMentorshipCreationModal.vue'

export default {
  components: {
    BFormInput,
    BModal,
    BPagination,
    BTable,
    ConfirmMentorshipCreationModal
  },
  filters: {
    upper
  },
  data() {
    return {
      purchasedSubscriptionPackage: null,
      selectedMentor: null,
      availableMentors: [],
      fields: [{
        key: 'id',
        label: 'ID',
        sortable: true
      },
      {
        key: 'user.surname',
        label: this.$t('Full name'),
        sortable: true,
      }],
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      currentPage: 1,
      searchQuery: null,
      sortBy: 'id',
      isSortDirDesc: true
    }
  },
  computed: {
    totalRows() {
      return this.availableMentors.length
    }
  },
  methods: {
    async show(purchasedSubscriptionPackage) {
      this.purchasedSubscriptionPackage = purchasedSubscriptionPackage
      await this.$store.dispatch('mentor/getAvailableMentorsForSubscriptionPackage',
        {
          id: this.purchasedSubscriptionPackage.id
        }
      )
        .then(response => {
          this.availableMentors = response.result
        })
        .catch((error) => {
          
        })
      this.$refs['create-new-mentorship-modal'].show()
    },
    onHidden() {
      this.availableMentors = []
      this.selectedMentor = null
      this.searchQuery = null
      this.currentPage = 1
    },
    mentorSelected(selectedRows) {
      this.selectedMentor = selectedRows[0]
    },
    askForConfirmation() {
      this.$refs['confirm-mentorship-creation-modal'].show()
    },
    async createNewMentorship() {
      const mentorshipCreated = await this.$store.dispatch('mentor/createMentorshipForSubscriptionPackage',
        {
          id: this.purchasedSubscriptionPackage.id,
          mentor_id: this.selectedMentor.id
        }
      )
      if(mentorshipCreated) {
        this.$bus.$emit('new-mentorship-created-for-mentee-by-admin')
        this.$root.$bvToast.toast(this.$t('Mentorship successfully created'), {
          title: this.$t('Confirmation'),
          variant: 'success',
          solid: true,
        })
        this.$refs['create-new-mentorship-modal'].hide()
      }
      else {
        this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
          title: this.$t('Caution'),
          variant: 'danger',
          solid: true,
        })
      }
    }
  }
}
</script>

<style scoped>

</style>

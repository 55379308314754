<template>
  <b-table
    :fields="fields"
    :items="scholarships"
    responsive
  >
    <template #head()="{label}">
      {{ label | upper }}
    </template>

    <template #cell(created_at)="{item}">
      {{ item.created_at | formatGreekDate({ month: '2-digit', year: 'numeric', day: '2-digit' }) }}
    </template>

    <template #cell(actions)="data">
      <div class="mentorship-request-actions">
        <b-button
          v-if="data.item.removable"
          variant="primary"
          @click="$emit('remove-scholarship', data.item)"
        >
          {{ $t('Remove scholarship') }}
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
import {
  BButton,
  BTable
} from 'bootstrap-vue'
import { upper, formatGreekDate } from '@mentoring-platform/utils/filters'

export default {
  components: {
    BButton,
    BTable
  },
  filters: {
    upper,
    formatGreekDate
  },
  props: {
    scholarships: {
      type: Array,
      required: true
    }
  },
  data() {
    return {

    }
  },
  computed: {
    fields() {
      return [{
        key: 'subscription_name',
        label: this.$t('Package')
      }, {
        key: 'name',
        label: this.$t('Scholarship')
      }, {
        key: 'created_at',
        label: this.$t('Creation date')
      }, {
        key: 'actions',
        label: this.$t('Actions')
      }]
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

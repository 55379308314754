<template>
  <div>
    <page-header-admin class="mb-2" :title="$t('Mentee page')" />
    <b-row>
      <b-col>
        <b-card>
          <mentee-basic-card v-if="mentee" :mentee="mentee" />
        </b-card>
      </b-col>
    </b-row>
    <b-card>
      <b-tabs pills>
        <b-tab active>
          <template #title>
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Mentorships & packages') }}
            </span>  
          </template>
          <div v-if="mentee" class="mentee-mentorships">
            <current-mentorship :title="$t('Current mentorship')" :mentee="mentee" />
            <my-packages :mentee="mentee" />
            <pending-mentorship-requests :mentee="mentee" />
          </div>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="Edit2Icon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Edit profile') }}
            </span>
          </template>
          <template v-if="mentee">
            <b-card class="mb-3">
              <personal-details ref="personal-details" :mentee="mentee" :email-pre-check="false" />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('personal-details')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <b-card class="mb-3">
              <professional-details ref="professional-details" :mentee="mentee" />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('professional-details')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <b-card class="mb-3" v-if="mentee.reason_for_mentoring && mentee.reason_for_mentoring.length > 0">
              <mentoring-details ref="mentoring-details" :mentee="mentee" />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('mentoring-details')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <b-card class="mb-3" v-if="mentee.reason_for_coaching && mentee.reason_for_coaching.length > 0">
              <coaching-details ref="coaching-details" :mentee="mentee" />
              <b-button variant="primary" class="my-2" @click="saveProfileChanges('coaching-details')" >
                {{ $t('Save changes') }}
              </b-button>
            </b-card>
            <required-field-explanation />
          </template>
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="StarIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Scholarships') }}
            </span>
          </template>
          <scholarships v-if="mentee" :mentee="mentee" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="ClipboardIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Comments') }}
            </span>
          </template>
          <admin-comments v-if="mentee" :mentee="mentee" />
        </b-tab>

        <b-tab>
          <template #title>
            <feather-icon
              icon="MessageSquareIcon"
              size="16"
              class="mr-0 mr-sm-50"
            />
            <span class="d-none d-sm-inline">
              {{ $t('Sent messages') }}
            </span>
          </template>
          <mentee-messages
            v-if="mentee"
            :mentee="mentee"
          />
        </b-tab>
      </b-tabs>
    </b-card>
    <!-- <b-row>
      <b-col>
        <b-button variant="danger">
          {{ $t('Delete mentee') }}
        </b-button>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BRow,
  BTab,
  BTabs
} from 'bootstrap-vue'
import { useMenteeRepository } from '@mentoring-platform/composables'
import MenteeBasicCard from '@mentoring-platform/views/components/blocks/MenteeAdmin/MenteeBasicCard.vue'
import CoachingDetails from '@mentoring-platform/views/components/blocks/CoachingDetails.vue'
import MentoringDetails from '@mentoring-platform/views/components/blocks/MentoringDetails.vue'
import PageHeaderAdmin from '@mentoring-platform/views/components/blocks/PageHeaderAdmin.vue'
import PersonalDetails from '@mentoring-platform/views/components/blocks/PersonalDetails.vue'
import ProfessionalDetails from '@mentoring-platform/views/components/blocks/ProfessionalDetails.vue'
import Scholarships from '@mentoring-platform/views/components/blocks/MenteeAdmin/Scholarships.vue'
import RequiredFieldExplanation from '@mentoring-platform/views/components/blocks/RequiredFieldExplanation.vue'
import CurrentMentorship from '@mentoring-platform/views/components/blocks/MenteeMentorships/CurrentMentorship.vue'
import MyPackages from '@mentoring-platform/views/components/blocks/PurchaseHistory/MyPackages.vue'
import PendingMentorshipRequests from '@mentoring-platform/views/components/blocks/MenteeAdmin/PendingMentorshipRequests.vue'
import AdminComments from '@mentoring-platform/views/components/blocks/MenteeAdmin/AdminComments.vue'
import MenteeMessages from '@mentoring-platform/views/components/blocks/MenteeAdmin/MenteeMessages.vue'

export default {
  components: {
    AdminComments,
    BButton,
    BCard,
    BCol,
    BRow,
    BTab,
    BTabs,
    CoachingDetails,
    CurrentMentorship,
    MentoringDetails,
    MenteeBasicCard,
    MenteeMessages,
    MyPackages,
    PageHeaderAdmin,
    PendingMentorshipRequests,
    PersonalDetails,
    ProfessionalDetails,
    RequiredFieldExplanation,
    Scholarships
  },
  data() {
    return {
      mentee: null
    }
  },
  async created() {
    this.mentee = await this.getSpecificMentee(this.$route.params.id)
  },
  methods: {
    async saveProfileChanges(ref) {
      const isValid = await this.$refs[ref].isValid()
      if(isValid) {
        const payload = this.$refs[ref].collectData()
        payload.mentee_id = this.mentee.id
        this.$store.dispatch('mentee/menteeEdit', payload)
          .then(async reponse => {
            this.mentee = await this.getSpecificMentee(this.$route.params.id)
            this.$root.$bvToast.toast(this.$t('Data successfully saved'), {
              title: this.$t('Confirmation'),
              variant: 'success',
              solid: true,
            })
          })
          .catch(error => {
            this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
              title: this.$t('Caution'),
              variant: 'danger',
              solid: true,
            })
          })
      }
    }
  },
  setup() {
    const { getSpecificMentee } = useMenteeRepository()

    return {
      getSpecificMentee
    }
  },
}
</script>

<style lang="scss">
.mentee-mentorships{

  .no-data-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

}
</style>
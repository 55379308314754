<template>
  <b-modal
    id="send-message-modal"
    ref="send-message-modal"
    centered
    size="lg"
    :title="$t('Send message')"
    title-class="font-weight-bolder"
    :ok-title="$t('Send message')"
    ok-only
    @ok.prevent="sendMessage"
    @hidden="onHidden"
  >
    <div class="mb-2">
      <b-card-text class="font-weight-bolder">
        {{ $t('Message for mentee') }}
      </b-card-text>
      <validation-observer ref="send-message-modal-observer">
        <validation-provider
          v-slot="{ errors }"
          name="message"
          rules="required|min:3|max:1400"
          mode="lazy"
        >
          <b-form-textarea
            v-model="message"
            rows="5"
            no-resize
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BFormTextarea,
  BModal,
} from 'bootstrap-vue'
import { required, min, max } from '@mentoring-platform-validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BCardText,
    BFormTextarea,
    BModal,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    mentee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      message: '',
    }
  },
  methods: {
    show() {
      this.$refs['send-message-modal'].show()
    },
    onHidden() {
      this.message = ''
    },
    sendMessage() {
      this.$refs['send-message-modal-observer'].validate().then(async success => {
        if (success) {
          this.$store.dispatch('mentee/sendMessageToMentee', { mentee_id: this.mentee.id, message: this.message })
            .then(response => {
              this.$refs['send-message-modal'].hide()
              this.$bus.$emit('message-sent', this.mentee)
              this.$root.$bvToast.toast(this.$t('Message successfully sent'), {
                title: this.$t('Confirmation'),
                variant: 'success',
                solid: true,
              })
            })
            .catch(error => {
              this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
                title: this.$t('Caution'),
                variant: 'danger',
                solid: true,
              })
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
.ql-editor {
  height: 250px;
}
</style>

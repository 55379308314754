<template>
  <b-modal
    id="remove-scholarship-modal"
    ref="remove-scholarship-modal"
    centered
    size="md"
    :title="$t('Remove scholarship')"
    title-class="font-weight-bolder"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-primary"
    :ok-title="$t('Comfirm removal')"
    @ok.prevent="removeScholarship"
  >
    <b-card-text>
      {{ $t('Are you sure that you want to remove this scholarship from mentee?') }}
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BModal
} from 'bootstrap-vue'
import { useScholarship } from '@mentoring-platform/composables'

export default {
  components: {
    BCardText,
    BModal
  },
  data() {
    return {
      scholarship: null
    }
  },
  methods: {
    show(scholarship) {
      this.scholarship = scholarship
      this.$refs['remove-scholarship-modal'].show()
    },
    async removeScholarship() {
      this.removeScholarshipFromMentee(this.scholarship)
        .then(response => {
          this.$refs['remove-scholarship-modal'].hide()
          this.$bus.$emit('mentee-scholarships-list-needs-refresh')
          this.showSuccess(response)
        })
        .catch(error => {
          this.showError(error)
        })
    },
    showSuccess() {
      this.$root.$bvToast.toast(this.$t('Scholarship successfully removed'), {
        title: this.$t('Confirmation'),
        variant: 'success',
        solid: true,
      })
    },
    showError() {
      this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
        title: this.$t('Caution'),
        variant: 'danger',
        solid: true,
      })
    }
  },
  setup() {
    const { removeScholarshipFromMentee } = useScholarship()

    return {
      removeScholarshipFromMentee
    }
  }
}
</script>

<style scoped>

</style>

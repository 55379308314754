<template>
  <b-modal
    id="give-scholarship-to-mentee-modal"
    ref="give-scholarship-to-mentee-modal"
    centered
    size="md"
    :title="$t('Give scholarship to mentee')"
    title-class="font-weight-bolder"
    ok-only
    :ok-title="$t('Save')"
    @ok.prevent="giveScholarship"
  >
    <validation-observer ref="give-scholarship-to-mentee-observer">
      <b-row class="mb-2">
        <b-col md="8">
          <validation-provider
            name="selectedSubscription"
            rules="required"
            mode="lazy"
            v-slot="{ errors }"
          >
            <label class="font-weight-bolder">
              {{ $t('Package') }}
            </label>
            <b-form-group >
              <v-select
                v-model="selectedSubscription"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Choose')"
                label="text"
                :options="availableSubscriptionForScholarshipOptions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col md="8">
          <validation-provider
            name="selectedScholarship"
            rules="required"
            mode="lazy"
            v-slot="{ errors }"
          >
            <label class="font-weight-bolder">
              {{ $t('Scholarship') }}
            </label>
            <b-form-group >
              <v-select
                v-model="selectedScholarship"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :placeholder="$t('Choose')"
                label="text"
                :options="scholarshipOptions"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BCol,
  BFormGroup,
  BModal,
  BRow,
} from 'bootstrap-vue'
import { useScholarship, useSubscriptionRepository } from '@mentoring-platform/composables'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@mentoring-platform-validations'
import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BCol,
    BFormGroup,
    BModal,
    BRow,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  props: {
    mentee: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedSubscription: null,
      selectedScholarship: null
    }
  },
  methods: {
    show() {
      this.$refs['give-scholarship-to-mentee-modal'].show()
    },
    isValid() {
      return this.$refs['give-scholarship-to-mentee-observer'].validate().then(success => success)
    },
    async giveScholarship() {
      const isValid = await this.isValid()
      if(isValid) {
        const payload = {
          mentee_id: this.mentee.id,
          subscription_id: this.selectedSubscription.value,
          scholarship_id: this.selectedScholarship.value
        }
        this.giveScholarshipToMentee(payload)
          .then(response => {
            this.$refs['give-scholarship-to-mentee-modal'].hide()
            this.$bus.$emit('mentee-scholarships-list-needs-refresh')
            this.$bus.$emit('mentee-subscriptions-list-needs-refresh')
            this.showSuccess(response)
          })
          .catch(error => {
            this.showError(error)
          })
      }
    },
    showSuccess(response) {
      this.$root.$bvToast.toast(this.$t('Scholarship successfully added'), {
        title: this.$t('Confirmation'),
        variant: 'success',
        solid: true,
      })
    },
    showError(error) {
      this.$root.$bvToast.toast(this.$t('An error occured, please try again later'), {
        title: this.$t('Caution'),
        variant: 'danger',
        solid: true,
      })
    }
  },
  setup() {
    const { getScholarships, scholarshipOptions, giveScholarshipToMentee } = useScholarship()
    const { getAvailableSubscriptions, availableSubscriptionForScholarshipOptions } = useSubscriptionRepository()
    getScholarships()
    getAvailableSubscriptions()

    return {
      scholarshipOptions,
      availableSubscriptionForScholarshipOptions,
      giveScholarshipToMentee
    }
  }
}
</script>

<style scoped>

</style>

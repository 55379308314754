<template>
  <b-modal
    id="confirm-mentorship-creation-modal"
    ref="confirm-mentorship-creation-modal"
    centered
    size="lg"
    :title="$t('Confirm mentorship creation')"
    title-class="font-weight-bolder"
    @ok="cofirmMentorshipCreation"
    :ok-title="$t('Confirmation')"
    :cancel-title="$t('Cancel')"
    cancel-variant="outline-primary"
  >
    <b-card-text>
      {{ $t('Mentorship creation confirmation message') }}
    </b-card-text>
  </b-modal>
</template>

<script>
import {
  BCardText,
  BModal
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BModal
  },
  data() {
    return {

    }
  },
  methods: {
    async show() {
      this.$refs['confirm-mentorship-creation-modal'].show()
    },
    cofirmMentorshipCreation() {
      this.$emit('creation-confirmed')
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <b-card :title="$t('Sent messages')">
      <messages-table :messages="menteeMessages" />
      <b-button
        variant="primary"
        @click="sendMessage"
      >
        {{ $t('Send message') }}
      </b-button>
      <send-message-modal
        v-if="mentee"
        ref="send-message-modal"
        :mentee="mentee"
      />
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
} from 'bootstrap-vue'
import MessagesTable from '@mentoring-platform/views/components/blocks/MessagesTable.vue'
import SendMessageModal from '@mentoring-platform/views/components/blocks/MenteeAdmin/SendMessageModal.vue'
import { useMenteeMessages } from '@mentoring-platform/composables'

export default {
  components: {
    BButton,
    BCard,
    MessagesTable,
    SendMessageModal,
  },
  props: {
    mentee: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  beforeMount() {
    this.$bus.$on('message-sent', this.getMenteeMessages)
  },
  beforeDestroy() {
    this.$bus.$off('message-sent', this.getMenteeMessages)
  },
  methods: {
    sendMessage() {
      this.$refs['send-message-modal'].show()
    },
  },
  setup(props) {
    const { menteeMessages, getMenteeMessages } = useMenteeMessages()
    getMenteeMessages(props.mentee)

    return {
      menteeMessages,
      getMenteeMessages,
    }
  },
}
</script>
